<script>
import NotCompletedCandidatures from './Candidature/NotCompletedCandidatures.vue'
import HorizontalMenu from '@/components/general/HorizontalMenu'
import GenericSlider from '@/components/general/GenericSlider'
import Breadcrumbs from '@/components/general/Breadcrumbs'

export default {
  name: 'Candidature',
  components: {
    Breadcrumbs,
    HorizontalMenu,
    GenericSlider,
    NotCompletedCandidatures
  },
  data () {
    return {
      isLoading: true,
      selectedMenu: 0,
      positions: {},
      notFinishedCandidatures: [],
      underAnalysisCandidatures: [],
      savedCandidatures: [],
      acceptedRemoteInterviews: []
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'main.header:candidacy', href: this.$route.path }]
      return path
    },
    menuOptions () {
      return [
        { to: { name: this.selectedWorkspace.type + '.candidature.under.analysis' }, text: 'candidature.list:under.analysis.title', icon: 'mdi-briefcase-clock' },
        { to: { name: this.selectedWorkspace.type + '.candidature.closed' }, text: 'candidature.list:closed.title', icon: 'mdi-briefcase-remove' },
        { to: { name: this.selectedWorkspace.type + '.candidature.saved' }, text: 'candidature.list:saved.positions.title', icon: 'mdi-heart' }
      ]
    },
    showMenu () {
      return this.updateShowMenu()
    }
  },
  methods: {
    setPositions (val) {
      this.notFinishedCandidatures = val.filter(candidature => candidature.position.status !== 'no_filter')
      this.underAnalysisCandidatures = val.filter(candidature => candidature.position.status === 'no_filter')
      this.acceptedRemoteInterviews = val.filter(candidature => candidature.position.meeting && candidature.position.meeting.accepted).sort((a, b) => new Date(a.position.meeting.date) - new Date(b.position.meeting.date)).map(position => position.position)

      this.positions = {
        notFinished: this.notFinishedCandidatures,
        underAnalysis: this.underAnalysisCandidatures
      }
    },
    updateShowMenu () {
      setTimeout(() => {
        this.isLoading = false
      }, 600)
      return this.isLoading
    }
  },
  watch: {
    '$route.path': {
      handler (val) {
        if (val.indexOf('under-analysis') !== -1) this.isLoading = true
      }
    }
  }
}
</script>
<template>
  <section class="candidature--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ $t('candidature.header:title') }}</h2>
      <GenericSlider v-if="acceptedRemoteInterviews.length" :title="$t('home.messages:booked.interviews')" :items="acceptedRemoteInterviews" type="acceptedRemote" />
      <NotCompletedCandidatures :positions="positions" v-if="selectedMenu === 0 && notFinishedCandidatures.length > 0"/>
      <horizontal-menu v-if="!showMenu" class="candidature--horizontal-menu" :options="menuOptions"
        v-model="selectedMenu"></horizontal-menu>
    </div>
    <router-view @positions="val => setPositions(val)"></router-view>
  </section>
</template>
<style lang="scss">
.candidature--container {

  .candidature--horizontal-menu {
    margin-top: 60px;
  }

  .loading-candidature--wrapper {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .candidature-left--wrapper {

    // width: calc(100% - 300px);
    .candidature-header--wrapper {
      h3 {
        color: rgba(36, 35, 33, 1);
        font-family: $lato;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 8px;
      }

      p {
        font-family: $lato;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: rgba(126, 125, 119, 1)
      }
    }

    .item-list-view-more--container {
      margin-bottom: 80px;
    }
  }

  .candidature-right--wrapper {
    width: 300px;
    padding: 24px;
  }

  @media only screen and (max-width: 768px) {
    .candidature-left--wrapper {
      width: 100%;
      padding: 0 20px;
      margin: 0 auto;
    }

    .candidature-right--wrapper {
      display: none;
    }
  }

  .candidature--header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .v-btn {
      margin-bottom: 50px;
      font-weight: bold;

      .v-icon {
        margin-left: 0px;
      }
    }

    .candidature--header {
      h5 {
        font-size: 16px;
        color: #242321;
      }

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #7E7D77;

        &.title-warning {
          color: #FF8888;
        }
      }
    }
  }
}
</style>
